import { Checkbox, Grid, Link, Typography } from '@mui/material';
import useLinksQuery from '_api/Links';
import {
  Button,
  Form,
  InputSelect,
  InputText,
  LinkAnchorTo,
  Page,
  PageHeading,
  SelectOptionInterface,
} from 'components';
import TermsOfUseModal from 'components/user-registration/modal/terms-of-use-modal';
import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { TermsOfUse } from 'interfaces/user-account';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmailRegex } from 'static';
import { parseTemplate } from 'url-template';
import './register-page.scss';
import { Routes } from 'pages';
import Notification from 'components/notification';

interface IForm {
  CompanyName: string;
  Email: string;
  MobilePhone: string;
  Password: string;
  ConfirmPassword: string;
  Language: string;
  AllowMarketingEmails: boolean;
  AcceptedTermsOfUseVersion: string;
}

const defaultValues = {
  Language: '',
  CompanyName: '',
  Email: '',
  MobilePhone: '',
  Password: '',
  ConfirmPassword: '',
  AllowMarketingEmails: false,
  AcceptedTermsOfUseVersion: '',
};

const languagesOptions: SelectOptionInterface[] = [
  {
    label: 'Select Language',
    value: '',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Norwegian',
    value: 'no',
  },
  {
    label: 'Swedish',
    value: 'se',
  },
];

interface ErrorShape {
  data: {
    Errors: {
      CompanyName: string[];
      Email: string[];
      MobilePhone: string[];
      MobilePhoneCountryCode: string[];
      Password: string[];
      ConfirmPassword: string[];
      Language: string[];
    };
    Message: string;
  };
  status: number;
  statusText: '';
}

const CompanyRegistrationPage = (): JSX.Element => {
  const baseClassName = 'account-registration-page';
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: endpoints } = useLinksQuery();
  const [searchParams] = useSearchParams();
  const register = searchParams.get('register');
  const [termsOfUse, setTermsOfUse] = useState<TermsOfUse>();
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const methods = useForm<IForm>({ defaultValues: defaultValues });
  const { control, handleSubmit, watch, setError } = methods;
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string[] | undefined>();
  const [serverSideFromErrors, setServerSideFromErrors] =
    useState<ErrorShape>();

  const createPersonalAccountLink = useMemo(
    () => getApiHrefByRel(endpoints ?? [], 'create-company-account'),
    [endpoints],
  );

  const termsOfUseLink = useMemo(
    () => getApiHrefByRel(endpoints ?? [], 'terms-of-use'),
    [endpoints],
  );
  const [phoneCountryCode, setPhoneCountryCode] = useState('47');

  useEffect(() => {
    const getLanguageCode = (): string => {
      switch (i18n.language) {
        case 'en':
          return 'en-us';
        case 'no':
          return 'nb-no';
        case 'se':
          return 'sv-se';
        default:
          return 'en-us';
      }
    };

    if (termsOfUseLink) {
      const urlTemplate = parseTemplate(decodeURI(termsOfUseLink));
      const apiLink = urlTemplate.expand({
        culture: getLanguageCode(),
      });
      axiosGet<TermsOfUse>(apiLink).then((response) => {
        setTermsOfUse(response?.data);
      });
    }
  }, [termsOfUseLink, i18n.language]);

  const onSubmit = async (data: IForm) => {
    if (createPersonalAccountLink) {
      if (!validatePassword(data.Password)) {
        return false;
      }

      setLoading(true);
      try {
        data.AcceptedTermsOfUseVersion = termsOfUse?.Version ?? '';
        data.MobilePhone = '+' + phoneCountryCode + ' ' + data.MobilePhone;
        await axiosPost(createPersonalAccountLink, data);
        navigate(Routes.AccountLogin + '?register=success');
      } catch (error) {
        const errorObj = error as ErrorShape;
        //form server side validation errors
        if (errorObj.status === 400) {
          setServerSideFromErrors(errorObj);
          setLoading(false);
          return;
        }
        navigate(Routes.Landing + '?register=failed');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (serverSideFromErrors) {
      const { Errors } = serverSideFromErrors.data;
      Object.entries(Errors).forEach((entry) => {
        const [key, error] = entry;
        const errorMessage = {
          type: 'manual',
          message: String(error[0]),
        };

        //created switch because - 'setError' method's 1st param is the type of FormField name only.
        switch (key) {
          case 'CompanyName':
            setError('CompanyName', errorMessage);
            break;
          case 'Email':
            setError('Email', errorMessage);
            break;
          case 'MobilePhone':
            setError('MobilePhone', errorMessage);
            break;
          case 'Password':
            setError('Password', errorMessage);
            break;
          case 'Language':
            setError('Language', errorMessage);
            break;
          default:
            setApiError(error);
        }
      });
    }
  }, [serverSideFromErrors, setError]);
  const validatePassword = (password: string) => {
    if (/^\s|\s$/.test(password)) {
      setError('Password', {
        type: 'manual',
        message: "Password shouldn't start or end with whitespace",
      });
      return false;
    }
    if (password.length < 8) {
      setError('Password', {
        type: 'manual',
        message: 'Password should be atleast 8 characters long',
      });
      return false;
    }

    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    const digit = /[0-9]/;
    const specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    let count = 0;

    if (upperCase.test(password)) count++;
    if (lowerCase.test(password)) count++;
    if (digit.test(password)) count++;
    if (specialChar.test(password)) count++;

    if (count >= 2) {
      return true;
    } else {
      setError('Password', {
        type: 'manual',
        message:
          'Password must contain at least 2 of the following: uppercase, lowercase, digit, special character',
      });
      return false;
    }
  };

  return (
    <Page className={baseClassName}>
      <PageHeading heading="Registration" />

      <p style={{ cursor: 'pointer', margin: '0 auto', textAlign: 'right' }}>
        <span onClick={() => navigate('/register')}>{t('User')}</span> |
        <b>{t('Company account')}</b>
      </p>
      {register === 'success' ? (
        <Notification severity="success">
          {t('User has been register successfully.')}
        </Notification>
      ) : register === 'failed' ? (
        <Notification severity="error" variant="filled">
          {t('User has not registered due to some Unexpected Error')}
        </Notification>
      ) : null}
      {apiError && (
        <Notification
          isAutoClosing={false}
          onClose={() => {
            setApiError(undefined);
          }}
          severity="error"
        >
          {apiError.map((msg) => (
            <span key={msg}>{msg}</span>
          ))}
        </Notification>
      )}

      <Form className={'form-custom'} onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={7}>
            <InputText
              name="CompanyName"
              label={t('Company name')}
              type="text"
              control={control}
              rules={{
                required: t('Company name is required.'),
              }}
              required
              className="input-field"
            />
          </Grid>
          <Grid item xs={5}>
            <InputText
              name="Reference"
              label={t('Internal reference')}
              type="text"
              control={control}
              className="input-field"
            />
          </Grid>
        </Grid>

        <InputText
          name="Email"
          label={t('Email')}
          type="email"
          control={control}
          rules={{
            required: t('Email field is required.'),
            validate: (val: string) => {
              if (!EmailRegex.test(val)) {
                return t('Please enter valid email address.');
              }
            },
          }}
          required
          className="input-field"
        />

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <PhoneInput
              enableSearch={true}
              value={phoneCountryCode}
              onChange={setPhoneCountryCode}
              inputClass="phone-code-input"
              placeholder={t('Country code')}
              searchClass="phone-code-input-search"
              specialLabel={phoneCountryCode && t('Country code') + '*'}
              regions={'europe'}
              preserveOrder={['preferredCountries']}
              preferredCountries={['dk', 'fi', 'no', 'se']}
            />
          </Grid>
          <Grid item xs={8}>
            <InputText
              name="MobilePhone"
              label={t('Mobile phone')}
              type="text"
              control={control}
              rules={{
                required: t('Mobile phone field is required.'),
              }}
              maxLength={19}
              required
              className="input-field"
            />
          </Grid>
        </Grid>

        <InputText
          name="Password"
          label={t('Password')}
          type="password"
          control={control}
          rules={{
            required: t('Password field is required.'),
            minLength: {
              value: 8,
              message: t('Password must be at least 8 characters long'),
            },
            validate: (val: string) => {
              if (watch('Password') !== val) {
                return t('Your passwords did not match');
              }
            },
          }}
          required
          className="input-field"
        />

        <InputText
          name="ConfirmPassword"
          label={t('Confirm password')}
          type="password"
          control={control}
          rules={{
            required: t('Confirm password field is required.'),
            minLength: {
              value: 8,
              message: t('Password must be at least 8 characters long'),
            },
            validate: (val: string) => {
              if (watch('Password') !== val) {
                return t('Password & confirm password does not match');
              }
            },
          }}
          required
          className="input-field"
        />

        <InputSelect
          name="Language"
          label={t('Language')}
          type="text"
          control={control}
          options={languagesOptions}
          className="language-selector"
          rules={{
            required: t('Language field is required.'),
          }}
          required
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            marginLeft: '-10px',
          }}
        >
          <Controller
            name="AllowMarketingEmails"
            control={control}
            render={({ field }) => <Checkbox {...field} />}
          />
          {t('Allow marketing emails')}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-10px',
          }}
        >
          <Controller
            name="AcceptedTermsOfUseVersion"
            control={control}
            render={({ field }) => <Checkbox {...field} required />}
          />
          <span style={{ marginRight: 8 }}>{t('Accept terms of use')} </span>
          <Link
            style={{ cursor: 'pointer' }}
            onClick={() => setShowTermsOfUse(true)}
          >
            {t('See here')}
          </Link>
        </div>
        <Typography
          variant="caption"
          sx={{ letterSpacing: '0', fontSize: '12px', textAlign: 'left' }}
        >
          {t(
            'To complete registration you will need to confirm and activate your account. To activate your account you need to press the activation link in the email sent to your registered email account. If you can not find it in your inbox, please check the junk or spamfolders.',
          )}
        </Typography>
        <Button
          type="submit"
          iconWidth={25}
          loading={loading}
          disabled={loading}
        >
          {t('Register')}
        </Button>
      </Form>
      <Typography variant="h6" sx={{ letterSpacing: '0', fontSize: '18px' }}>
        {t('Already have an account?')}
        <LinkAnchorTo to="/login"> {t('Login here')}</LinkAnchorTo>
      </Typography>
      {showTermsOfUse && (
        <TermsOfUseModal
          handleClose={() => setShowTermsOfUse(false)}
          termsOfServiceContent={termsOfUse?.TermsOfUse ?? ''}
        />
      )}
    </Page>
  );
};

export default CompanyRegistrationPage;
