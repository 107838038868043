import { TranslatedString } from './no';

const seJSON: { [key in TranslatedString]: string } = {
  // Languages
  Login: 'Logga in',
  'Language switcher': 'Språkväxling',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svenska',
  'Total amount (Including Tax)': 'Totalt belopp (inklusive skatt)',

  'Go back': 'Gå tillbaka',
  'Pay with card': 'Betala med kort',
  'Choose payment method': 'Välj betalsätt',

  Start: 'Start',
  Continue: 'Fortsätta',

  'Registration number': 'Registreringsnummer',
  Submit: 'Skicka',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Skicka recept',
  Home: 'Hem',
  'Add vehicle': 'Lägg till fordon',
  'Edit vehicle': 'Redigera fordon',

  'All set to go': 'Allt klart att köra',
  'Payment failed': 'Betalning misslyckades',

  'License plate': 'Registreringsskylt',

  'Total cost': 'Total kostnad',
  'Try again': 'Försök igen',

  'Parking area': 'Parkeringsplass',
  Amount: 'Belopp',
  Started: 'Satte igång',
  Duration: 'Varighet',
  Price: 'Pris',
  ' at ': ' kl. ',

  'Access terminal': 'Åtkomstterminal',

  Security: 'säkerhet',
  'Enter your registration number': 'Ange ditt registreringsnummer',
  Next: 'Nästa',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det finns ingen parkering på {{RegistrationNumber}}. Är du säker på att du har skrivit rätt?',

  Pay: 'Betala',
  'There are no parking sessions for your license plate number {{thePlateNumber}}':
    'Det finns inga parkeringssessioner för ditt registreringsnummer {{thePlateNumber}}',
  Refresh: 'Uppdatera',

  'Parking sessions were outdated. We’ve updated the new details now.':
    'Parkeringssessioner var föråldrade. Vi har uppdaterat de nya detaljerna nu.',
  'Payment aborted': 'Betalning avbruten',

  'Parking is now paid and we want you to be safe until your next destination.':
    'Parkering är nu betald och vi vill att du ska vara säker till din nästa destination.',

  'Terms and conditions': 'Villkor',
  'No terms and conditions for operator ID {{operatorGuid}} with language code {{langCode}}':
    'Inga villkor för operatörs-ID {{operatorGuid}} med språkkod {{langCode}}',

  'Total (Incl. Tax)': 'Totalt (inkl. moms)',

  'Payment complete': 'Betalning komplett',
  'Request receipt': 'Begära kvitto',
  'Email address': 'E-postadress',
  'Send receipt': 'Skicka kvitto',
  'Something went wrong with sending the receipt':
    'Något gick fel med att skicka kvittot',
  'Your receipt will be sent to your inbox shortly':
    'Kvitteringen vil bli sendt til innboksen din innen kort tid',
  'Please enter valid email address': 'Ange giltig e -postadress',
  'Successfully paid <b>{{total}}</b> to <b>{{operatorName}}</b>':
    'Framgångsrikt betalas <b> {{total}} </b> till <b> {{operationName}} </b>',

  'Successfully paid <b>{{total}}</b>':
    'Framgångsrikt betalas <b> {{total}} </b>',
  'Complete payment': 'Fullständig betalning',

  'Uncompleted payments': 'Ofullständiga betalningar',
  at: 'på',

  Registration: 'Registrering',
  Register: 'Registrera',
  User: 'Användare',
  'Company account': 'Företagskonto',
  'First name': 'Förnamn',
  'First name field is required.': 'Fältets förnamn krävs.',
  'Last name': 'Efternamn',
  'Last name field is required.': 'Fältets efternamn krävs.',
  Email: 'E-post',
  'Email field is required.': 'E -postfält krävs.',
  'Please enter valid email address.': 'Ange giltig e -postadress.',
  'Mobile phone': 'Mobiltelefon',
  'Mobile phone field is required.': 'Mobiltelefonfält krävs.',
  'Country code': 'Landskod',
  'Country code field is required.': 'Landskodsfält är obligatoriskt.',
  Password: 'Lösenord',
  'Password field is required.': 'Lösenordsfält krävs.',
  'Confirm password': 'Bekräfta lösenord',
  'Confirm password field is required.': 'Bekräfta lösenordsfältet krävs.',
  'Your passwords did not match': 'Dina lösenord matchade inte',
  'Password & confirm password does not match':
    'Lösenord och bekräfta lösenordet matchar inte',
  Language: 'Språk',
  'Language field is required.': 'Språkfält krävs.',
  'Allow marketing emails': 'Tillåt e -postmeddelanden till marknadsföring',
  'Accept terms of use': 'Acceptera användarvillkor',
  'See here': 'Kolla här',
  'User has been register successfully.':
    'Användaren har registrerats framgångsrikt.',
  'User has not registered due to some Unexpected Error':
    'Användaren har inte registrerat sig på grund av något oväntat fel',
  'Terms Of Use': 'Villkor',
  'Invalid license plate': 'Ogiltig registreringsskylt',
  'To complete registration you will need to confirm and activate your account. To activate your account you need to press the activation link in the email sent to your registered email account. If you can not find it in your inbox, please check the junk or spamfolders.':
    'För att slutföra registreringen måste du bekräfta och aktivera ditt konto. För att aktivera ditt konto behöver du klicka på aktiveringslänken i det e-postmeddelande som skickats till din registrerade e-postadress. Om du inte hittar den i din inkorg, var god kontrollera skräppostmappen eller spam-mappen.',
  Country: 'land',
  'Country field is required': 'Fältet Land är obligatoriskt',
  loading: 'läser in',
  'Email verified successfully. Redirecting to login...':
    'E-posten har verifierats. Omdirigerar till inloggning...',
  'Error verifying email. Please try again.':
    'Fel vid verifiering av e-post. Var god försök igen.',
  'Reset Password': 'Återställ lösenord',
  'Set Password': 'Välj lösenord',
  'Email is successfully sent to mail id.': 'E-post har skickats till mail-id.',
  'Apply for membership': 'Ansök om medlemskap',
  'Already have an account?': 'Har du redan ett konto?',
  'Login here': 'Logga in här',
  'Forgot password?': 'Glömt ditt lösenord?',
  'Dont have an account?': 'Har du inget konto?',
  'Create one here': 'Skapa en här',
  'Password cannot exceed 15 characters':
    'Lösenordet får inte överstiga 15 tecken',
  'Password must be at least 8 characters long':
    'Lösenordet måste vara minst 8 tecken långt',
  'Please enter password': 'Ange lösenord Tack',
  'Invalid email address': 'Ogiltig e-postadress',
  'Please enter email': 'Vänligen ange e-post',
  'Vehicle added successfully': 'Fordonet har lagts till',
  'Please enter License plate': 'Vänligen ange registreringsskylt',
  'Please enter description': 'Vänligen ange beskrivning',
  'Description cannot exceed 15 characters':
    'Beskrivningen får inte överstiga 15 tecken',
  'Vehicle updated successfully': 'Fordonet har uppdaterats',
  'Error while updating vehicle': 'Fel vid uppdatering av fordonet',
  '+ Add vehicle': '+ Lägg till fordon',
  Vehicles: 'Fordon',
  Memberships: 'Medlemskap',
  'Password reset successfully, click here to login':
    'Lösenordsåterställningen lyckades, klicka här för att logga in',
  'Please enter new password': 'Vänligen ange nytt lösenord',
  Apply: 'Tillämpa',
  'Membership plans': 'Medlemskapsplaner',
  Cancel: 'Avbryt',
  'Add comment': 'Lägg till kommentar',
  Comment: 'Kommentar',
  'Write your comment here ...': 'Skriv din kommentar här...',
  'Add subscription': 'Lägg till prenumeration',
  'Available subscriptions': 'Tilgjengelige abonnement',
  Subscribe: 'Prenumerera',
  Description: 'Beskrivelse',
  'License plate was not found and no payment is due. Please check if license plate is correct':
    'Registreringsskylten hittades inte och ingen betalning ska betalas. Kontrollera om registreringsskylten är korrekt',
  // Menu items
  Dashboard: 'Dashboard',
  Membership: 'Medlemskap',
  Subscriptions: 'Abonnement',
  Payment: 'Betaling',
  Logout: 'Logga av',

  'Payment needs to be completed': 'Betalingen må gjennomføres',
  'within 15 minutes': 'innen 15 minutter',

  Loading: 'Laster',
  'Payment was cancelled': 'Betalingen ble kansellert',
  'Payment was successful': 'Betalingen var vellykket',

  Yes: 'Ja',
  No: 'Nej',
  'Are you sure you want to delete the vehicle with license plate':
    'Är du säker på att du vill radera fordonet med registreringsskylt',

  'Vehicle deleted successfully': 'Fordon raderades framgångsrikt',
  'Could not delete the vehicle': 'Kunde inte ta bort fordonet',

  'Load more vehicles': 'Lasta fler fordon',

  'Company name': 'Företagsnamn',
  'Internal reference': 'Intern referens',

  'Sign up': 'Anmäl dig',
  'Create new account': 'Registrer bruker',

  Period: 'Periode',
  days: 'dager',
  'Expires at': 'Utløper kl',
  'Max users': 'Maks brukere',
  'Company name is required.': 'Firmanavn er påkrevd.',

  'For company registration': 'För företagsregistrering',
  'For personal registration': 'For bedriftsregistrering',
  'click here': 'klicka här',
  'Temporarily unavailable. Please try again later':
    'Tillfälligt otillgänglig. Vänligen försök igen senare',

  'Are you sure you want to cancel this subscription?':
    'Er du sikker på at du vil kansellere dette abonnementet?',
};

export default seJSON;
