import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { IInputControllerProps } from 'components';
import './_input-text.scss';
import { FC } from 'react';

export const InputText: FC<IInputControllerProps> = ({
  name,
  control,
  readOnly = false,
  label,
  required,
  type = 'text',
  className = 'input-text',
  onValueChange,
  maxLength,
  rules,
  onFocus,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={(
        { field: { value, onChange }, fieldState: { error } }, // formState
      ) => (
        <TextField
          type={type}
          className={className}
          value={value}
          label={label}
          disabled={readOnly}
          helperText={error ? error.message : null}
          error={!!error}
          required={required}
          onChange={onValueChange ? onValueChange : onChange}
          variant="standard"
          fullWidth
          inputProps={{ maxLength: maxLength }}
          onFocus={onFocus}
        />
      )}
    />
  );
};
