import { Heading6, LinkAnchorTo, Loader, Page, PageHeading } from 'components';

import useParkingSessions from 'hooks/use-parking-session';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import Notification from 'components/notification';
import LicensePlateSessions from 'components/parking-details/license-plate';
import {
  ICarPark,
  LicensePlateSessionsGroup,
  OperatorSessionsGroup,
  PaymentPackageItem,
  PaymentPackage,
} from 'interfaces';
import { useSelector } from 'react-redux';
import { setParkingSessionsResponse } from 'redux/parking-sessions';
import { RootState, dispatch } from 'redux/store';
import { Routes } from 'pages';
import './_parking-details-page.scss';

export const ParkingDetailsPage: FC = () => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const regNo = new URLSearchParams(search).get('regNo');
  const operatorGuid = new URLSearchParams(search).get('operatorguid');
  const carparkGuid = new URLSearchParams(search).get('carparkguid');

  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);

  const { isLoading, apiResponse: parkingSessionsApiResponse } = useSelector(
    (state: RootState) => state.parkingSessionsReducer,
  );

  const [unCompletedPaymentOrders, setUnCompletedPaymentOrders] = useState<
    LicensePlateSessionsGroup[] | undefined
  >();

  const parkingSessionItems: LicensePlateSessionsGroup[] | undefined =
    parkingSessionsApiResponse?.Items;

  const { getParkingSessionInformation, getUncompletedPayments } =
    useParkingSessions();

  const { t } = useTranslation();
  const baseClassName = 'parking-details-page';

  useEffect(() => {
    const getCarParkDetails = (
      parkingSessions: PaymentPackageItem[],
      IanaTimeZone: string,
    ): ICarPark[] => {
      const carParks: ICarPark[] = [];

      //picked unique carParks
      const carParkGuids = new Set(
        parkingSessions.map((session) => session.CarParkGuid),
      );

      //then for each carpark set its basic details & parking sessions
      carParkGuids.forEach((carParkGuid) => {
        const session: PaymentPackageItem | undefined = parkingSessions.find(
          (session) => session.CarParkGuid === carParkGuid,
        );

        if (session) {
          const carPark: ICarPark = {
            CarParkGuid: carParkGuid,
            Name: session.CarParkName,
            Address: {
              City: session.City,
              Coordinates: '',
              Country: '',
              IanaTimeZone: IanaTimeZone,
              Street: session.Street,
              ZipCode: session.ZipCode,
            },
            //setting up parking sessions as per carpark
            ParkingSessions:
              parkingSessions
                .filter((item) => item.CarParkGuid === carParkGuid)
                .map((item) => {
                  return {
                    ParkingFee: {
                      TotalAmount:
                        item.TotalAmount.Amount +
                        ' ' +
                        item.TotalAmount.Currency,
                      VatAmount:
                        item.VatAmount.Amount + ' ' + item.VatAmount.Currency,
                      CoversUntil: '',
                    },
                    ParkingSessionGuid: item.ParkingSessionGuid,
                    PaymentDeadline: '',
                    EnteredAt: item.EnteredAt,
                    ExitedAt: item.ExitedAt,
                  };
                }) ?? [],
          };
          carParks.push(carPark);
        }
      });
      return carParks;
    };

    const formatOrdersToLicensePlateSessionsGroups = (
      orders: PaymentPackage[],
    ): LicensePlateSessionsGroup[] | undefined => {
      const result: LicensePlateSessionsGroup[] = [];
      const operatorSessionsGroups: OperatorSessionsGroup[] = [];

      if (!orders || orders.length === 0) {
        return result;
      }

      //Setting up operators with its car park details
      orders.forEach((order) => {
        const operatorSessionsGroup: OperatorSessionsGroup = {
          Name: order.OperatorName,
          Links: order.Links,
          OperatorGuid: order.OperatorGuid,
          TotalParkingFee: {
            TotalAmount:
              order.TotalAmount.Amount + ' ' + order.TotalAmount.Currency,
            VatAmount: order.VatAmount.Amount + ' ' + order.VatAmount.Amount,
            CoversUntil: '',
          },
          CarParks: getCarParkDetails(order.Items, order.IanaTimeZone) ?? [],
        };

        operatorSessionsGroups.push(operatorSessionsGroup);
      });

      result.push({
        LicensePlate: orders ? orders[0].LicensePlate : regNo ?? '',
        Operators: operatorSessionsGroups,
      });

      return result;
    };

    const setParkingSessionData = async () => {
      if (regNo && !parkingSessionItems) {
        const response = await getParkingSessionInformation(
          regNo,
          operatorGuid,
          carparkGuid,
        );

        if (response?.data.Items) {
          dispatch(setParkingSessionsResponse(response?.data));
        }

        if (!unCompletedPaymentOrders) {
          const response = await getUncompletedPayments(regNo);
          if (response?.data.Orders) {
            setUnCompletedPaymentOrders(
              formatOrdersToLicensePlateSessionsGroups(response.data.Orders) ??
                [],
            );
          }
        }
      }
    };

    setParkingSessionData();
  }, [
    carparkGuid,
    getParkingSessionInformation,
    getUncompletedPayments,
    operatorGuid,
    parkingSessionItems,
    regNo,
    unCompletedPaymentOrders,
  ]);

  useEffect(() => {
    //Remove canceled variable from query string
    if (searchParams.has('canceled')) {
      setIsPaymentCanceled(Boolean(searchParams.get('canceled')));
      searchParams.delete('canceled');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const result = useMemo(() => {
    if (isLoading) {
      return <Loader loading={true} className="loader page-loading" />;
    }

    const unCompleted = () => {
      if (!unCompletedPaymentOrders || !unCompletedPaymentOrders.length) {
        return <></>;
      }

      return (
        <>
          {unCompletedPaymentOrders.map((item, index) => (
            <LicensePlateSessions
              isUnCompletePaymentOrder={true}
              key={index}
              data={item}
            />
          ))}
        </>
      );
    };

    const items = () => {
      if (!parkingSessionItems || !parkingSessionItems.length) {
        return <></>;
      }

      return (
        <>
          {parkingSessionItems.map((item) => (
            <LicensePlateSessions key={item.LicensePlate} data={item} />
          ))}
        </>
      );
    };

    return (
      <>
        {unCompleted()} {items()}
      </>
    );
  }, [isLoading, parkingSessionItems, regNo, t, unCompletedPaymentOrders]);

  return (
    <Page className={baseClassName}>
      <PageHeading heading="Parking details" />

      {isPaymentCanceled && (
        <Notification severity="error">{t('Payment aborted')}</Notification>
      )}

      {typeof parkingSessionsApiResponse?.PricesRefreshedMessage ===
        'string' && (
        <Notification severity="warning">
          {t(
            'Parking sessions were outdated. We’ve updated the new details now.',
          )}
        </Notification>
      )}
      <Heading6>{regNo}</Heading6>
      {result}
      <LinkAnchorTo to={Routes.Landing}>{t('Go back')}</LinkAnchorTo>
    </Page>
  );
};
