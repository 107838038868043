import { Button, Heading4 } from 'components';
import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import {
  ILastPaymentSession,
  OperatorSessionsGroup,
  ParkingSessionsApiResponse,
  PaymentPackage,
} from 'interfaces';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcons } from 'shared';
import { Alignment } from 'static';
import './_operator-parking-sessions.scss';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import CarParkGroup from 'components/parking-details/car-park';
import { dispatch } from 'redux/store';
import { setParkingSessionsResponse } from 'redux/parking-sessions';
import { TranslatedString } from 'i18n/translations/no';
import { Alert } from '@mui/material';

interface Props {
  data: OperatorSessionsGroup;
  isUnCompletePaymentOrder?: boolean;
}

const OperatorParkingSessions: FC<Props> = ({
  data,
  isUnCompletePaymentOrder,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const listDivRef = useRef<HTMLDivElement>(null);
  const [showPayButtonDetails, setShowPayButtonDetails] = useState(false);
  const [error, setError] = useState<TranslatedString | null>(null);
  const { search } = useLocation();
  const regNo = new URLSearchParams(search).get('regNo');

  const [packageDetails, setPackageDetails] = useState<PaymentPackage>();

  useEffect(() => {
    const selfLink = getApiHrefByRel(data.Links, 'self');
    if (selfLink) {
      axiosGet<PaymentPackage>(selfLink).then((response) => {
        if (response && response?.status === 200) {
          setPackageDetails(response.data);
        }
      });
    }
  }, [data.Links]);

  const payLink = getApiHrefByRel(packageDetails?.Links ?? [], 'pay');

  const handlePay = async (operator: OperatorSessionsGroup) => {
    setError(null);
    const { href, pathname } = window.location;

    const paymentPayload = {
      CompleteUrl: '/payment-complete',
      CancelUrl: `${
        pathname + href.substring(href.indexOf('?'))
      }&canceled=true`,
      TermsOfServiceUrl: `terms-and-conditions?operatorguid=${operator.OperatorGuid}`,
    };

    setIsLoading(true);

    const handlePayResponseData = (data: ParkingSessionsApiResponse) => {
      console.log('handling pay response data..', { data });
      const { Items, Links } = data;

      if (!!Items && Items.length > 0) {
        console.log(
          "There were items and had length. Dispathicn setParkingSessionResponse. Unsure what's happening here",
        );
        dispatch(setParkingSessionsResponse(data));
      } else {
        const lastPaymentSessionData: ILastPaymentSession = {
          LicensePlate: regNo ?? '',
          OperatorName: operator.Name,
          TotalParkingFee: operator.TotalParkingFee,
          RequestReceiptLink:
            Links.find((link) => link.Rel === 'request-receipt')?.Href ?? '',
        };

        window.localStorage.setItem(
          'last_payment_session_data',
          JSON.stringify(lastPaymentSessionData),
        );

        const paymentGateWayLink = getApiHrefByRel(Links, 'pay');

        if (paymentGateWayLink) {
          window.location.href = paymentGateWayLink;
        } else {
          console.error(
            "Did not forward the user to payment gateway because there was no 'pay' link in the response",
          );
        }
      }
    };

    const handleRedirectLocation = (link: string | undefined) => {
      if (!link) {
        /**
         * if there is no link to handle, nothing more we can do here
         */
        return;
      }

      let triedTimes = 0;
      const retryInterval = setInterval(async () => {
        triedTimes++;

        try {
          const response = await axiosGet<ParkingSessionsApiResponse>(link);
          if (response?.status === 200) {
            const { data } = response;
            handlePayResponseData(data);
            console.log('Got successfull response from redirect Response', {
              link,
              data,
            });
            clearInterval(retryInterval);
          }
        } catch (error) {
          console.error(
            'Error on GET to redirect location. Will try again in 1 second',
            { link, error },
          );
        }

        if (triedTimes > 5) {
          console.error(
            'Tried more than 5 times to get response from redirect location. All failed, stopping',
            { link, error },
          );
          setError('Temporarily unavailable. Please try again later');
          clearInterval(retryInterval);
        }
      }, 1000);
    };

    try {
      if (!payLink) {
        setError('Temporarily unavailable. Please try again later');
        throw Error(
          'Could not continue with handle payment because pay link is not available',
        );
      }
      const response = await axiosPost<ParkingSessionsApiResponse>(
        payLink,
        paymentPayload,
      );

      const redirectLocation = response?.headers['location'] ?? undefined;
      if (redirectLocation) {
        handleRedirectLocation(redirectLocation);
        return;
      }

      /**
       * Used the redirect response if that's usable,
       * selse falling back to initial response
       */

      if (response?.data) {
        handlePayResponseData(response.data);
      }
    } catch (error) {
      setError('Temporarily unavailable. Please try again later');
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleUnCompletedPayment = async () => {
    if (packageDetails) {
      const paymentGateWayLink = getApiHrefByRel(packageDetails.Links, 'pay');

      setIsLoading(true);

      const lastPaymentSessionData: ILastPaymentSession = {
        LicensePlate: regNo ?? '',
        OperatorName: packageDetails.OperatorName,
        TotalParkingFee: packageDetails.TotalAmount,
        RequestReceiptLink:
          packageDetails.Links.find((link) => link.Rel === 'request-receipt')
            ?.Href ?? '',
      };
      window.localStorage.setItem(
        'last_payment_session_data',
        JSON.stringify(lastPaymentSessionData),
      );

      if (paymentGateWayLink) {
        window.location.href = paymentGateWayLink;
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowPayButtonDetails(entry.isIntersecting);
      },
      { threshold: 0.7 },
    );
    if (listDivRef.current) {
      observer.observe(listDivRef.current);
    }
  }, []);

  return (
    <div className="operator-parking-sessions">
      <Heading4 className="operator-name">{data.Name}</Heading4>

      <div ref={listDivRef}>
        {data.CarParks.map((carPark) => (
          <CarParkGroup data={carPark} key={carPark.CarParkGuid} />
        ))}
      </div>

      {showPayButtonDetails && !!payLink && (
        <div className="sticky-pay-bar">
          <Heading4 className="payment-total">
            {t('Total (Incl. Tax)')}{' '}
            <span className="heading4">{data.TotalParkingFee.TotalAmount}</span>
          </Heading4>
          <br />

          <Button
            disabled={isLoading}
            loading={isLoading}
            formatClassName="pay-button"
            onClick={() =>
              isUnCompletePaymentOrder
                ? handleUnCompletedPayment()
                : handlePay(data)
            }
            iconName={SvgIcons.ArrowRight}
            iconPosition={Alignment.Right}
            iconWidth={25}
          >
            {t(isUnCompletePaymentOrder ? 'Complete payment' : 'Pay')}{' '}
            {isUnCompletePaymentOrder}
          </Button>

          {!!error && (
            <Alert
              severity="error"
              variant="filled"
              style={{ maxWidth: '400px', margin: '0 auto' }}
            >
              {t('Temporarily unavailable. Please try again later')}
            </Alert>
          )}
        </div>
      )}
    </div>
  );
};

export default OperatorParkingSessions;
