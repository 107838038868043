import { Grid, Button } from '@mui/material';
import ConfirmationModal from 'components/modal/confirmation';
import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { SubscribedPlan } from 'interfaces/subscription';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  selfLink: string;
};

const SubscriptionExpansion: FC<Props> = ({ selfLink }) => {
  const { t } = useTranslation();

  const [subscriptionData, setSubscriptionData] = useState<
    undefined | SubscribedPlan
  >();

  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);

  useEffect(() => {
    axiosGet<SubscribedPlan>(selfLink).then((r) => {
      setSubscriptionData(r?.data ?? undefined);
    });
  }, [selfLink]);

  const cancelLink = getApiHrefByRel(subscriptionData?.Links ?? [], 'cancel');

  if (!cancelLink) {
    return <></>;
  }

  const handleCancelSubscription = () => {
    axiosPost(cancelLink, {}).then(() => {
      setShowConfirmCancelModal(false);
      window.location.reload();
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        className="subscription-list-item-expanded"
      >
        <Button
          className="subscription-list-item-cancel-button"
          onClick={() => setShowConfirmCancelModal(true)}
        >
          {t('Cancel')}
        </Button>
      </Grid>
      {showConfirmCancelModal && (
        <ConfirmationModal
          isVisible={showConfirmCancelModal}
          onCancel={() => setShowConfirmCancelModal(false)}
          onConfirm={handleCancelSubscription}
        >
          <h3>{subscriptionData?.Name}</h3>
          {t('Are you sure you want to cancel this subscription?')}
        </ConfirmationModal>
      )}
    </>
  );
};

export default SubscriptionExpansion;
