import React, { FC, useEffect, useState, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import { parseTemplate } from 'url-template';
import { t } from 'i18next';

import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';

import { Loader } from 'components/loader/loader';
import Modal from 'components/modal/Modal';

import './SubscriptionModal.scss';
import {
  SubscriptionPlan,
  SubscriptionPlansApiResponse,
} from 'interfaces/subscription';
import { Heading6 } from 'components/headings/heading6/heading6';
import { Button } from 'components/clickers/button/button';
import { Heading3 } from 'components/headings/heading3/heading3';

interface Props {
  isOpen: boolean;
  subscriptionPlansLink: string;
  setIsOpen: (val: boolean) => void;
  refreshSubscriptions: () => Promise<void>;
}

const SubscriptionModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  subscriptionPlansLink,
  refreshSubscriptions,
}) => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlan[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleApplyForSubscription = async (plan: SubscriptionPlan) => {
    setIsLoading(true);
    try {
      const subscriptionSelfLink = getApiHrefByRel(plan.Links, 'self');
      if (!subscriptionSelfLink) {
        console.error(
          'Cannot get subscription because there is no self link in links:',
          plan.Links,
        );
        throw Error(
          'Cannot get subscription because there is no self link in links',
        );
      }
      const response = await axiosGet(subscriptionSelfLink);
      const selfResponse = response?.data as SubscriptionPlan;
      const subscriptionApplyLink = getApiHrefByRel(
        selfResponse.Links,
        'subscribe',
      );
      if (!subscriptionApplyLink) {
        console.error(
          'Cannot get subscription because there is no self link in links:',
          selfResponse.Links,
        );
        throw Error(
          'Cannot apply for subscription because there is no subscribe link in links',
        );
      }
      const currentDate = new Date();
      const startTime = currentDate.toISOString();

      const subscribeResponse = await axiosPost(subscriptionApplyLink, {
        StartTime: startTime,
        CompleteUrl: '/dashboard/subscriptions?is_completed=true',
        CancelUrl: '/dashboard/subscriptions?is_cancelled=true',
        TermsOfServiceUrl: '/terms-and-conditions',
      });

      if (subscribeResponse?.status && subscribeResponse.status > 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 3 sec
        await refreshSubscriptions();
        setIsOpen(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error);
      } else {
        console.error('Error applying for subscription:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getSubscriptionPlans = useCallback(async () => {
    try {
      setIsLoading(true);
      const urlTemplate = parseTemplate(decodeURI(subscriptionPlansLink));
      const apiLink = urlTemplate.expand({ pagesize: 50, page: 0 });

      const response = await axiosGet(apiLink);
      const data = response?.data as SubscriptionPlansApiResponse;
      setSubscriptions(data.Items as SubscriptionPlan[]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching Available subscriptions:', error);
    }
  }, [subscriptionPlansLink]);

  useEffect(() => {
    if (isOpen && subscriptionPlansLink) {
      getSubscriptionPlans();
    }
  }, [isOpen, subscriptionPlansLink, getSubscriptionPlans]);

  return (
    <Modal isVisible={isOpen} onClose={() => setIsOpen(false)}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="main"
      >
        <Grid item xs={12} textAlign="center" paddingBottom={3}>
          <Heading3>{t('Available subscriptions')}</Heading3>
        </Grid>

        {isLoading && (
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <Loader loading={isLoading} />
          </Grid>
        )}
        <Grid item xs={12} className="subscription">
          {subscriptions?.map((subscription) => (
            <Grid
              key={subscription.SubscriptionPlanGuid}
              className="membership-list-item"
              style={{ padding: '15px' }}
            >
              <Grid>
                <Heading6>{subscription.Name}</Heading6>
                {!!subscription.PeriodDays && (
                  <Typography component="p" className="secondary-text">
                    <b>{t('Period')}</b>: {subscription.PeriodDays} {t('days')}
                  </Typography>
                )}
                {!!subscription.MaxConcurrentUses && (
                  <Typography component="p" className="secondary-text">
                    <b>{t('Max users')}</b>: {subscription.MaxConcurrentUses}{' '}
                  </Typography>
                )}
              </Grid>
              <Grid>
                <Typography component="p" className="secondary-text">
                  <Heading6 style={{ textAlign: 'center' }}>
                    <b>{subscription.TotalAmount}</b> kr
                  </Heading6>
                </Typography>
                <Button
                  className="membership-list-item-apply-button"
                  type="submit"
                  onClick={() => handleApplyForSubscription(subscription)}
                  loading={isLoading}
                  style={{
                    fontSize: '16px',
                    display: 'flex',
                    padding: '5px 10px',
                    border: '2px solid black',
                    justifyContent: 'center',
                  }}
                  disabled={isLoading}
                >
                  {t('Subscribe')}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SubscriptionModal;
