import { FC, ReactNode } from 'react';
import { Modal as MaterialModal, styled } from '@mui/material';
import { Page } from 'components/layout/page/page';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalContent = styled('div')({
  background: 'rgb(255, 216, 63)',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  margin: '20px',
  borderRadius: '8px',
});

const StyledModal = styled(MaterialModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface Props {
  isVisible: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: FC<Props> = ({ isVisible, onClose, children }) => {
  return (
    <Page>
      <StyledModal
        open={isVisible}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>
          <Grid item xs={12} sx={{ textAlign: 'end', width: '100%' }}>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
          </Grid>
          {children}
        </ModalContent>
      </StyledModal>
    </Page>
  );
};

export default Modal;
