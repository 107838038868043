import {
  Button,
  Form,
  FormGroup,
  InputText,
  LinkAnchorTo,
  Loader,
  Page,
  PageHeading,
} from 'components';
import Notification from 'components/notification';
import { ensureLicenseNumberFormat } from 'helpers/input-validation-helper';
import queryString from 'query-string';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from 'pages';
import './_landing-page.scss';
import useParkingSessions from 'hooks/use-parking-session';
import { Typography } from '@mui/material';

interface IForm {
  inputRegistrationNumber: string;
  inputCountries: string;
}

const defaultValues = {
  inputRegistrationNumber: '',
  inputCountries: 'NOR',
};

export function LandingPage(): JSX.Element {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<IForm>({ defaultValues: defaultValues });
  const { control, handleSubmit, setValue } =
    methods; /*  reset, setValue, watch */
  const [searchParams] = useSearchParams();
  const operatorGuid = searchParams.get('operatorguid');
  const carParkGuid = searchParams.get('carparkguid');
  const register = searchParams.get('register');
  const navigate = useNavigate();
  const { getParkingSessionInformation, getUncompletedPayments } =
    useParkingSessions();

  const [error, setError] = useState(false);

  // TODO: Refactor later on. Right now is for demo purposes
  const onSubmit = async (data: IForm) => {
    setError(false);
    const queryParams = queryString.stringify(
      {
        operatorguid: operatorGuid,
        carparkguid: carParkGuid,
        regNo: ensureLicenseNumberFormat(data.inputRegistrationNumber),
      },
      {
        skipNull: true,
      },
    );
    if (ensureLicenseNumberFormat(data.inputRegistrationNumber)) {
      const response = await getParkingSessionInformation(
        ensureLicenseNumberFormat(data.inputRegistrationNumber),
        operatorGuid,
        carParkGuid,
      );

      const responseOrders = await getUncompletedPayments(
        ensureLicenseNumberFormat(data.inputRegistrationNumber),
      );
      if (!response?.data.Items.length && !responseOrders?.data.Orders.length) {
        setError(true);
        setIsLoading(false);
        return;
      }
      if (response?.data.Items) {
        setIsLoading(true);
        setError(false);
        navigate(Routes.ParkingDetails + '?' + queryParams);
      }
    }
  };

  const baseClassName = 'landing-page';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue('inputRegistrationNumber', ensureLicenseNumberFormat(value));
  };
  const handleFocus = () => {
    setError(false);
  };

  return (
    <Page className={baseClassName}>
      <PageHeading heading="Start" />

      {register === 'success' && (
        <Notification severity="success">
          {t('User has been register successfully.')}
        </Notification>
      )}
      {register === 'failed' && (
        <Notification severity="error" variant="filled">
          {t('User has not registered due to some Unexpected Error')}
        </Notification>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup formatClassName="dropdown-combo">
          <InputText
            name="inputRegistrationNumber"
            label={t('Registration number')}
            control={control}
            required
            onValueChange={handleChange}
            maxLength={8}
            onFocus={handleFocus}
          />
          <>
            {error && (
              <Notification
                isAutoClosing={false}
                severity={'error'}
                sx={{ cursor: 'pointer', margin: '10px 0' }}
              >
                {t(
                  'License plate was not found and no payment is due. Please check if license plate is correct',
                )}
              </Notification>
            )}
          </>
        </FormGroup>
        <Button type="submit">
          <Loader loading={isLoading}>{t('Submit')}</Loader>
        </Button>
      </Form>
      <br />
      <br />
      <Typography variant="h6" sx={{ letterSpacing: '0', fontSize: '18px' }}>
        <LinkAnchorTo to="/login"> {t('Login')}</LinkAnchorTo> |{' '}
        <LinkAnchorTo to="/register"> {t('Create new account')}</LinkAnchorTo>
      </Typography>
    </Page>
  );
}
