const noJSON = {
  // Languages
  'Language switcher': 'Språkbytter',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svensk',
  'Total amount (Including Tax)': 'Totale sum (Inkludert Mva)',

  'Go back': 'Gå tilbake',
  'Pay with card': 'Betal med kort',
  'Choose payment method': 'Velge betalingsmetode',

  Start: 'Start',
  Continue: 'Fortsette',

  'Registration number': 'Registreringsnummer',
  Submit: 'Send inn',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Send oppskrift',
  Home: 'Hjem',

  'All set to go': 'Alle klare til å gå',
  'Payment failed': 'Betaling mislyktes',

  'License plate': 'Registrerings nummer',
  Description: 'Beskrivelse',

  'Total cost': 'Total cost',
  'Try again': 'Prøv igjen',

  'Parking area': 'Parkeringsplass',
  Amount: 'Beløp',
  Started: 'Startet',
  Duration: 'Varighet',
  Price: 'Pris',
  ' at ': ' kl. ',

  'Access terminal': 'Tilgangsterminal',

  Security: 'Sikkerhet',
  'Enter your registration number': 'Skriv inn registreringsnummeret ditt',
  Next: 'Neste',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det er ingen parkering på {{RegistrationNumber}}. Er du sikker på at du har skrevet riktig?',

  Pay: 'Betale',
  'There are no parking sessions for your license plate number {{thePlateNumber}}':
    'Det er ingen parkeringsøkter for registreringsnummeret ditt {{thePlateNumber}}',
  Refresh: 'Forfriske',

  'Parking sessions were outdated. We’ve updated the new details now.':
    'Parkeringsøktene var utdaterte. Vi har oppdatert de nye detaljene nå.',
  'Payment aborted': 'Betaling avbrutt',
  'Parking is now paid and we want you to be safe until your next destination.':
    'Parkering er nå betalt og vi vil at du skal være trygg til neste destinasjon.',

  'Terms and conditions': 'Vilkår og betingelser',
  'No terms and conditions for operator ID {{operatorGuid}} with language code {{langCode}}':
    'Ingen vilkår og betingelser for operatør-ID {{operatorGuid}} med språkkode {{langCode}}',

  'Total (Incl. Tax)': 'Totalt (inkl. avgifter)',
  'Add vehicle': 'Legg til kjøretøy',

  'Payment complete': 'Betaling fullført',
  'Request receipt': 'Be om kvittering',
  'Email address': 'Epostadresse',
  'Send receipt': 'Send kvittering',
  'Something went wrong with sending the receipt':
    'Noe gikk galt med å sende kvitteringen',
  'Your receipt will be sent to your inbox shortly':
    'Kvitteringen vil bli sendt til innboksen din innen kort tid',
  'Please enter valid email address':
    'Vennligst skriv inn gyldig e -postadresse',
  'Successfully paid <b>{{total}}</b> to <b>{{operatorName}}</b>':
    'Vellykket betalt <b> {{total}} </b> til <b> {{operatorname}} </b>',

  'Successfully paid <b>{{total}}</b>': 'Vellykket betalt <b> {{total}} </b>',
  'Complete payment': 'Fullfør betaling',

  'Uncompleted payments': 'Ufullstendige betalinger',

  at: 'på',

  Registration: 'Registrering',
  Register: 'Registrere',
  User: 'Bruker',
  'Company account': 'Bedriftskonto',
  'First name': 'Fornavn',
  'First name field is required.': 'Fornavnfeltet er påkrevd.',
  'Last name': 'Etternavn',
  'Last name field is required.': 'Etternavn felt er påkrevd.',
  Email: 'E -post',
  'Email field is required.': 'E -postfelt er påkrevd.',
  'Please enter valid email address.':
    'Vennligst skriv inn gyldig e -postadresse.',
  'Mobile phone': 'Mobiltelefon',
  'Mobile phone field is required.': 'Mobiltelefonfelt er påkrevd.',
  'Country code': 'Landskode',
  'Country code field is required.': 'Landskodefeltet er obligatorisk.',
  Password: 'Passord',
  'Password field is required.': 'Passordfelt er påkrevd.',
  'Confirm password': 'Bekreft passord',
  'Confirm password field is required.': 'Bekreft passordfeltet er påkrevd.',
  'Your passwords did not match': 'Passordene dine stemte ikke overens',
  'Password & confirm password does not match':
    'Passord og bekrefter passordet stemmer ikke overens',
  Language: 'Språk',
  'Language field is required.': 'Språkfelt er påkrevd.',
  'Allow marketing emails': 'Tillat markedsførings -e -post',
  'Accept terms of use': 'Godta vilkår for bruk',
  'See here': 'Se her',
  'User has been register successfully.': 'Brukeren har blitt registrert.',
  'User has not registered due to some Unexpected Error':
    'Brukeren har ikke registrert på grunn av noen uventet feil',
  'To complete registration you will need to confirm and activate your account. To activate your account you need to press the activation link in the email sent to your registered email account. If you can not find it in your inbox, please check the junk or spamfolders.':
    'For å fullføre registreringen må du bekrefte og aktivere kontoen din. For å aktivere kontoen din må du trykke på aktiveringslenken i e-posten som er sendt til din registrerte e-postkonto. Hvis du ikke finner den i innboksen din, vennligst sjekk mappen for søppelpost eller spam.',
  'Terms Of Use': 'Vilkår for bruk',
  Login: 'Logg inn',
  'Invalid license plate': 'Ugyldig skilt',
  Country: 'Land',
  'Country field is required': 'Land-feltet er obligatorisk',
  loading: 'lasting',
  'Email verified successfully. Redirecting to login...':
    'E-posten er bekreftet. Omdirigerer til pålogging...',
  'Error verifying email. Please try again.':
    'Feil ved bekreftelse av e-post. Vær så snill, prøv på nytt.',
  'Reset Password': 'Tilbakestille passord',
  'Set Password': 'Lag et passord',
  'Email is successfully sent to mail id.': 'E-post er sendt til e-post-ID.',
  'Edit vehicle': 'Rediger kjøretøy',
  'Apply for membership': 'Søk om medlemskap',
  'Already have an account?': 'Har du allerede en konto?',
  'Login here': 'Logg inn her',
  'Forgot password?': 'Glemt passord?',
  'Dont have an account?': 'Har du ikke en konto?',
  'Create one here': 'Lag en her',
  'Password cannot exceed 15 characters':
    'Passordet kan ikke overstige 15 tegn',
  'Password must be at least 8 characters long':
    'Passordet må være minst 8 tegn langt',
  'Please enter password': 'Skriv inn passord',
  'Invalid email address': 'Ugyldig epostadresse',
  'Please enter email': 'Vennligst skriv inn e-post',
  'Vehicle added successfully': 'Kjøretøyet ble lagt til',
  'Please enter License plate': 'Vennligst skriv inn nummerskilt',
  'Please enter description': 'Vennligst skriv inn beskrivelse',
  'Description cannot exceed 15 characters':
    'Beskrivelsen kan ikke overstige 15 tegn',
  'Vehicle updated successfully': 'Kjøretøyet ble oppdatert',
  'Error while updating vehicle': 'Feil under oppdatering av kjøretøy',
  '+ Add vehicle': '+ Legg til kjøretøy',
  Vehicles: 'Kjøretøy',
  Memberships: 'Medlemskap',
  'Password reset successfully, click here to login':
    'Passord tilbakestilt, klikk her for å logge på',
  'Please enter new password': 'Vennligst skriv inn nytt passord',
  Apply: 'Søke om',
  Cancel: 'Avbryt',
  'Membership plans': 'Medlemskapsplaner',
  'Add comment': 'Legg til kommentar',
  Comment: 'Kommentar',
  'Write your comment here ...': 'Skriv din kommentar her...',
  'Add subscription': 'Legg til abonnement',
  'Available subscriptions': 'Tilgjengelige abonnement',
  Subscribe: 'Abonner',
  'License plate was not found and no payment is due. Please check if license plate is correct':
    'Skiltnummeret ble ikke funnet, derfor er det ingenting å betale. Sjekk gjerne om skiltnummer er skrevet inn korrekt',

  // Menu items
  Dashboard: 'Dashboard',
  Membership: 'Medlemskap',
  Subscriptions: 'Abonnement',
  Payment: 'Betaling',
  Logout: 'Logg av',

  'Payment needs to be completed': 'Betalingen må gjennomføres',
  'within 15 minutes': 'innen 15 minutter',
  Loading: 'Laster',
  'Payment was cancelled': 'Betalingen ble kansellert',
  'Payment was successful': 'Betalingen var vellykket',

  Yes: 'Ja',
  No: 'Nei',
  'Are you sure you want to delete the vehicle with license plate':
    'Er du sikker på at du vil slette kjøretøyet med skilt',

  'Vehicle deleted successfully': 'Kjøretøy slettet vellykket',
  'Could not delete the vehicle': 'Kunne ikke slette kjøretøyet',

  'Load more vehicles': 'Last flere kjøretøy',

  'Company name': 'Firmanavn',
  'Internal reference': 'Intern referanse',
  'Sign up': 'Registrer',

  'Create new account': 'Registrer bruker',
  Period: 'Periode',
  days: 'dager',

  'Expires at': 'Utløper kl',
  'Max users': 'Maks brukere',
  'Company name is required.': 'Firmanavn er påkrevd.',
  'For company registration': 'For bedriftsregistrering',
  'For personal registration': 'For bedriftsregistrering',
  'click here': 'klikk her',
  'Temporarily unavailable. Please try again later':
    'Midlertidig utilgjengelig. Vennligst prøv igjen senere',
  'Are you sure you want to cancel this subscription?':
    'Er du sikker på at du vil kansellere dette abonnementet?',
};

export type TranslatedString = keyof typeof noJSON;

export default noJSON;
